import { ReactSVG } from "react-svg";
import { colors } from "../../../core/styles";
import { styles } from "./PasswordIcon.styles";
import { useState } from "react";
const EyeIcon = require("./eye.svg");

export const PasswordIcon = ({ hasError = false, disabled = false, showPassword = false }) => {
  const iconColor = disabled ? colors.lightGrey.hex : hasError ? colors.red.hex : colors.headlineGrey.hex;
  const [animation, setAnimation] = useState<number | undefined>();
  return (
    <div
      id="passwordIcon"
      css={styles.iconContainer}
      onClick={() => setAnimation(1)}
      onAnimationEnd={() => setAnimation(0)}
      data-animate={animation}
    >
      <ReactSVG
        height={24}
        width={24}
        css={[styles.icon(iconColor), typeof animation === "number" && styles.animationOn(showPassword)]}
        src={EyeIcon}
      ></ReactSVG>
    </div>
  );
};
