import React, {
  ChangeEvent,
  FocusEventHandler,
  MouseEventHandler,
  MutableRefObject,
  useMemo,
  useRef,
  useState,
} from "react";
import { styles } from "./VoucherInput.style";
import { Input } from "../../../../elements/InputNew/Input";

export interface VoucherInputProps {
  inputName: string;
  placeholderMessage: string;
  error: string;
  submitButtonTestId?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (phrase: string, ref: MutableRefObject<HTMLInputElement>) => void;
  onBlur: FocusEventHandler<HTMLInputElement>;
  defaultValue?: string;
}

export const VoucherInput: React.FC<VoucherInputProps> = ({
  inputName,
  placeholderMessage,
  error,
  submitButtonTestId,
  onChange,
  onSubmit,
  onBlur,
  defaultValue = "",
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState(defaultValue);
  const [animationBounceArrow, setAnimationBounceArrow] = useState(false);
  const onClickAnimationHandler: MouseEventHandler = () => {
    setAnimationBounceArrow(!animationBounceArrow);
  };

  const onFormSubmit = (e) => {
    onSubmit(inputValue, inputRef);
    e.preventDefault();
  };

  const onInputChange = (e) => {
    setInputValue(e.target.value.toUpperCase());
    onChange(e);
  };

  const VoucherButton = useMemo(
    () => (
      <button
        type="submit"
        data-testid={submitButtonTestId}
        css={styles.searchIconWrapper()}
        onClick={onClickAnimationHandler}
      >
        <div css={styles.icon(animationBounceArrow)} />
      </button>
    ),
    [animationBounceArrow]
  );

  return (
    <form css={styles.form()} onSubmit={onFormSubmit}>
      <Input
        ref={inputRef}
        name={inputName}
        placeholder={placeholderMessage}
        onChange={onInputChange}
        onBlur={onBlur}
        hasError={!!error}
        message={error}
        customEndIcon={VoucherButton}
        defaultValue={defaultValue}
      />
    </form>
  );
};
